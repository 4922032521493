import querystring from "querystring";

import { Address, AgeGroup, CenterQuestion, Child, CommissionRate,
  Contract, Parent, Program, ProviderFile, Schedule, TourSchedule, WaitlistSpot } from "@legup/legup-model";
import ReactGA from "react-ga4";
import React from "react";

import moment from "moment";

import { ConciergeForm } from "../components/Concierge/ConciergeSurvey";
import logger from "../logger";

import strings from "./constants/strings";

export interface OpenSeatPreferences {
  preference_id?: string;
  child_id: string;
  birth_date: Date;
  parent_id: string;
  use_home_address: boolean;
  use_other_centers: boolean;
  use_alternate_address: boolean;
  home: Address;
  home_location?: { latitude: number, longitude: number };
  alternate: Address;
  alternate_location?: { latitude: number, longitude: number };
  route_points?: Array<{ latitude: number, longitude: number }>;
  miles_from_address: number;
  route_in_between: boolean;
  first_time_care: boolean;
  care_types: string[];
  preferred_date: Date;
  most_important_factors: string[],
  max_cost_amount: number;
  max_cost_frequency: "daily" | "weekly" | "monthly" | "annually";
  subsidy_eligible: "yes" | "no" | "dontknow";
  subsidies: string[];
  preferred_center_types: string[];
  schedule_days: number[];
  schedule_available_days: number;
  schedule_start_time: number;
  schedule_end_time: number;
  hear_about_us: string;
  basics: Array<{ profile_basic_id?: string, code?: string, values: string[] }>;
  last_result?: any;
}

export interface OnboardingForm {
  provider_onboarding_id?: string;
  user_id?: string;
  waitlist_preferences?: Array<"any" | "aftertour" | "noopening">;
  waitlist_url?: string; // URL of uploaded file or "none", "later", or "preexisting"
  tour_process?: Array<"any" | "afterwaitlist" | "opening">;
  tour_required?: boolean;
  enrollment_file_state?: string; // "none" or "later" if they choose not to upload files now
  provider_files?: ProviderFile[];
  no_subsidies?: boolean; // Set if they explicitly selected no subsidies
  subsidies?: string[];
  waitlist_fees?: {
    commission: CommissionRate,
    fees: { [ s: string]: {
      amount: number,
      legup: number,
      stripe: number,
    }},
    center_count: number,
  };
  completed?: boolean;
}

export interface CenterBasic {
  profile_basic_id: string;
  title: string;
  icon_url: string;
  choices: string[];
  multiple_choice: boolean;
  values: string[];
  default_value: string
}

export type CenterBasics = Array<CenterBasic> | undefined;

export const getTime = (hour: number, minute: number): string => {
  const isAM: boolean = (hour < 12);
  const h2: number = (hour === 12 || hour === 0) ? 12 : (hour % 12);
  let result: string;

  result = `${h2}:`;
  result += minute < 10 ? `0${minute}` : `${minute}`;
  result += (isAM ? " AM" : " PM");

  return result;
};

// Google Analytics functions
export const initGA = () => {
  if (process.env.googleAccountId) {
    ReactGA.initialize(process.env.googleAccountId);
  }
};

export const logPageView = (userId: string, groups: string) => {
  if (process.env.googleAccountId) {
    if (typeof window !== "undefined") {
      ReactGA.set({ dimension1: groups, userId });
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }
};

export const logEvent = (userId: string, groups: string, category: string, action: string) => {
  if (process.env.googleAccountId) {
    if (category && action) {
      if (userId || groups) {
        ReactGA.set({ dimension1: groups, userId });
      }
      ReactGA.event({ category, action });
    }
  }
};

export const logException = (description = "", fatal = false) => {
  if (fatal) {
    logger.error(description);
  }
  else {
    logger.info(description);
  }
};

// Add google maps API script Tag

export const loadScript = (src: string, position: any, id: string) => {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

export const formatDateTime = (date: Date, noYear?: boolean, localTime?: boolean): string => {
  function zeroPad(d: number) {
    return (`0${d}`).slice(-2);
  }

  const parsed = new Date(date);
  const result = (noYear)
    ? `${strings.months[parsed.getMonth()]} ${parsed.getDate()}`
    : `${strings.months[parsed.getMonth()]} ${parsed.getDate()}, ${parsed.getFullYear()}`;

  const isAM = (parsed.getHours() < 12);
  const hour = (parsed.getHours() === 12) ? 12 : (parsed.getHours() % 12);
  const minute = parsed.getMinutes();

  return `${result} ${hour}:${zeroPad(minute)} ${isAM ? "AM" : "PM"}`;
};

export const formatDate = (date: Date, monthOnly?: boolean, localTime?: boolean): string => {
  if (!date) {
    return "";
  }
  const parsed = new Date(date);
  const month = localTime ? parsed.getMonth() : parsed.getUTCMonth();
  const year = localTime ? parsed.getFullYear() : parsed.getUTCFullYear();
  const day = localTime ? parsed.getDate() : parsed.getUTCDate();

  if (monthOnly) {
    return `${strings.months[month]} ${year}`;
  }

  return `${strings.months[month]} ${day}, ${year}`;

};

export const formatDateForDatePicker = (date: Date): string => {
  function zeroPad(d: number) {
    return (`0${d}`).slice(-2);
  }
  const parsed = new Date(date);

  return [parsed.getUTCFullYear(), zeroPad(parsed.getUTCMonth() + 1), zeroPad(parsed.getUTCDate())].join("-");
};

export const getStartOfWeek = (start: Date, startDOW: number): Date => {
  const d: Date = new Date(start);
  const dow: number = d.getDay();
  let dayAdjust: number;

  // Adjust to desired DOW ... go forward if Saturday, backwards if not
  if (dow === 6) {
    dayAdjust = 1 + startDOW;
  }
  else {
    dayAdjust = startDOW - dow;
  }
  d.setDate(d.getDate() + dayAdjust);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);

  return d;
};

export const childAgeInWeeks = (date: Date, effectiveDate: Date | undefined): number => {
  if (!date) {
    return -1;
  }

  const now = (effectiveDate) || new Date();

  return Math.floor((now.valueOf() - date.valueOf()) / (1000 * 60 * 60 * 24 * 7));
};

export const childAgeInMonths = (date: Date, effectiveDate: Date | undefined): number => {
  if (!date) {
    return -1;
  }

  const now = (effectiveDate) || new Date();
  let months = 12 * (now.getUTCFullYear() - date.getUTCFullYear());
  months += (now.getUTCMonth() - date.getUTCMonth());
  if (now.getUTCDate() < date.getUTCDate()) {
    months--;
  }

  return months;
};

export const childAge = (date: Date | undefined, effectiveDate: Date | undefined): string => {
  if (!date) {
    return strings.waitlistChildren.trying;
  }

  let months = childAgeInMonths(date, effectiveDate);
  const years = Math.floor(months / 12);
  months %= 12;
  let format;

  if (months < 0) {
    return strings.waitlistChildren.notBorn;
  }

  if (years > 1) {
    format = (months === 1) ? strings.waitlistChildren.ageYearsMonth : strings.waitlistChildren.ageYearsMonths;
  }
  else if (years === 1) {
    format = (months === 1) ? strings.waitlistChildren.ageYearMonth : strings.waitlistChildren.ageYearMonths;
  }
  else {
    format = (months === 1) ? strings.waitlistChildren.ageMonth : strings.waitlistChildren.ageMonths;
  }

  return format.replace("{Year}", years).replace("{Month}", months);
};

// Family Name

// This returns an array of three strings - the first is the full combined name
// The second string is the first name
// The third string is the last name
// You can either pass in a waitlist spot, or a parent and child
export const getFamilyName = (spot: WaitlistSpot | undefined, parent?: Parent, child?: Child): string[] => {
  const p: Parent = spot?.getParents() ? spot.getParents()[0] : parent;
  const c: Child = spot?.getChild() || child;

  const first: string = c?.getFirstName() || strings.noFirstName;
  const last: string = c?.getLastName() || p?.getLastName() || strings.noLastName;

  return [`${first} ${last}`, first, last];
};

// Schedule and age strings

export const ageGroupRange = (obj: any, verbose: boolean) => {
  const formatStr = (obj.min_age_weeks)
    ? (verbose ? strings.directory.displayAgeWeeks : strings.directory.displayAgeWeeksCompact)
    : (verbose ? strings.directory.displayAge : strings.directory.displayAgeCompact);

  return formatStr.replace("{min}", obj.min_age).replace("{max}", obj.max_age);
};

// Cost formatting

function commaNumber(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatCost = (cost_amount: number, cost_frequency: string) => {
  const dollars = Math.floor(cost_amount / 100);
  const cents = cost_amount % 100;
  if (cents) {
    return `$${commaNumber(dollars)}.${(`0${cents}`).slice(-2)} ${strings.cost[cost_frequency]}`;
  }

  return `$${commaNumber(dollars)} ${strings.cost[cost_frequency]}`;

};

export const formatDeposit = (deposit_amount: number) => {
  const dollars = Math.floor(deposit_amount / 100);
  const cents = deposit_amount % 100;
  if (cents) {
    return `$${commaNumber(dollars)}.${(`0${cents}`).slice(-2)}`;
  }

  return `$${commaNumber(dollars)}`;

};

export const depositWithFee = (deposit_amount: number, commission: any, excludeCC?: boolean) => {
  let legup = commission ? Math.max(Math.ceil(deposit_amount * commission.percentage), commission.minimum) : 0;
  if (commission && commission.maximum) {
    legup = Math.min(legup, commission.maximum);
  }

  return (deposit_amount + legup);
};

// Waitlist and seat helper routines

export const determineList = (birthDate: Date | undefined, ageGroupList: AgeGroup[], preferredDate: Date) => {
  const getEvalDate = (d: Date, ageAsOf: Date | undefined, fixedDate: boolean) => {
    let evalDate: Date;
    const date: Date = d ? new Date(d) : new Date();
    if (ageAsOf) {
      evalDate = new Date(ageAsOf);
      evalDate.setUTCFullYear(date.getUTCFullYear());

      // If preferred date is before the eval date, jump to the previous year
      // Unless the evaluation date is fixed
      if (!fixedDate && ((date.getUTCMonth() < evalDate.getUTCMonth()) ||
        ((date.getUTCMonth() === evalDate.getUTCMonth()) && (date.getUTCDate() < evalDate.getUTCDate())))) {
        evalDate.setUTCFullYear(evalDate.getUTCFullYear() - 1);
      }
    }
    else {
      // Make sure the date is not in the past
      evalDate = (date && (date > new Date())) ? date : new Date();
    }

    return evalDate;
  };

  // Calculate the list ID
  let list_id: string = "0";
  let months: number;
  let preferred: Date;

  if (birthDate < new Date()) {
    // Check each age group to see which one this child should be in
    ageGroupList.forEach(a => {
      preferred = getEvalDate(preferredDate, a.getAgeAsOf(), !!a.getAgeAsOfFixed());
      months = 12 * (preferred.getUTCFullYear() - birthDate.getUTCFullYear());
      months += (preferred.getUTCMonth() - birthDate.getUTCMonth() + 1);

      if ((months >= a.getMinAge()) && (months <= a.getMaxAge())) {
        // This would be valid
        list_id = a.getListId();
      }
    });

    if (list_id === "0") {
      // Well, they have to go on one of the age group lists!
      let youngest;
      let oldest;
      ageGroupList.forEach(a => {
        if ((youngest === undefined) || (a.getMinAge() < youngest.getMinAge())) {
          youngest = a;
        }
        if ((oldest === undefined) || (a.getMaxAge() > oldest.getMaxAge())) {
          oldest = a;
        }
      });

      if (!oldest) {
        return null;
      }

      preferred = getEvalDate(preferredDate, oldest.getAgeAsOf(), !!oldest.getAgeAsOfFixed());
      months = 12 * (preferred.getUTCFullYear() - birthDate.getUTCFullYear());
      months += (preferred.getUTCMonth() - birthDate.getUTCMonth() + 1);
      if (months < youngest.getMinAge()) {
        list_id = youngest.getListId();
      }
      else {
        list_id = oldest.getListId();
      }
    }
  }
  else {
    // If no birthdate (or in the future), they are trying and automatically go on the youngest age group
    let youngest;
    ageGroupList.forEach(a => {
      if ((youngest === undefined) || (a.getMinAge() < youngest)) {
        // Use this one for now
        youngest = a.getMinAge();
        list_id = a.getListId();
      }
    });
  }

  return list_id;
};

export const formatCriteria = (criteria: any) => {
  let result = "";
  let gender = (criteria && criteria.gender) ? strings.seatCard[criteria.gender] : undefined;
  const name = (criteria && criteria.stages) ? criteria.stages.map(c => (c.reached
    ? strings.seatCard.reached
    : strings.seatCard.notReached)
    .replace("{stage}", strings.developmentStage[c.stage].toLowerCase())).join(" and ") : undefined;

  if (name) {
    gender = gender || strings.seatCard.genderAny;
    result = strings.seatCard.criteria.replace("{criteria}", name).replace("{gender}", gender);
  }
  else if (gender) {
    result = strings.seatCard.genderOnly.replace("{gender}", gender);
  }

  return result;
};

// Hack function to track ids of new items (for object correlation)

export const createNewId = (ids: string[]): string => {
  let id: number = 0;
  let v: string[];

  ids.forEach(i => {
    v = i.split(".");
    if ((v.length > 1) && (v[0] === "new")) {
      id = Math.max(id, parseInt(v[1], 10));
    }
  });
  id++;

  return `new.${id}`;
};

// Question management

export const getQuestionType = (question: CenterQuestion): string => {
  // Calculate the question type
  let questionType = question.getType() as string;
  if (questionType === "text") {
    if (question.choices) {
      questionType = ((question.choices.length === 2) &&
        question.choices.find(c => c.text.toLowerCase() === "yes") &&
        question.choices.find(c => c.text.toLowerCase() === "no")) ? "yesno" : "multiple";
    }
    else {
      questionType = "freeform";
    }
  }

  return questionType;
};

export const getQuestionPrioirtyRank = questions => {
  // Rank all priorities based on passed-in questions
  const rankedPriorities = [];
  (questions || []).forEach(qu => {
    if (qu && qu.getChoices()) {
      qu.getChoices().forEach(c => {
        if (c.priority && (c.priority > 10) && (rankedPriorities.indexOf(c.priority) === -1)) {
          rankedPriorities.push(c.priority);
        }
      });
    }
  });
  rankedPriorities.sort((a, b) => (b - a));

  return rankedPriorities;
};

// Input field validation

export const emailValidation = (email : any): boolean => {
  const regexPattern = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  if (regexPattern.test(email)) {
    return (true);
  }

  return (false);

};

export const zipcodeValidation = (zipcode : any): boolean => {
  const regexPattern = /^(\d{5})$/;
  if (regexPattern.test(zipcode)) {
    return (true);
  }

  return (false);

};

// Unlike emailValidation, this goes one step further and verifies against DNS
export const isEmailValid = async (email: string | undefined): Promise<boolean> => {
  let valid: boolean = false;

  if (email?.length) {
    const params = { email };
    const result = await fetch(`/api/email/verify?${querystring.stringify(params)}`);
    if (result.status === 200) {
      const data = await result.json();

      if (data.success) {
        valid = !!data.valid;
      }
    }
  }

  return valid;
};

export const isInputFeildEmpty = (inputField : any): boolean => ((inputField).length <= 0 || (inputField) === undefined || (inputField) == null);

export const phoneNumber = (to: string): string => {
  // We only support US phone numbers
  // Strip all non-numeric characters, and make sure it starts with "+1"
  let number: string = to ? to.replace(/\D/g, "") : "";
  if (number) {
    if (number.length === 10) {
      // Great - just add +1
      number = `+1${number}`;
    }
    else if (number.length === 7) {
      // No area code? Add 206
      number = `+1206${number}`;
    }
    else if (number.length > 10) {
      // Does it start with 1? If not, add +1
      if (number.charAt(0) !== "1") {
        number = `+1${number}`;
      }
      else {
        number = `+${number}`;
      }

      // Now, assume anything after the first 12 characters is an extension
      // So we'll just cut that off
      if (number.length > 12) {
        number = number.substr(0, 12);
      }
    }
    else {
      // Not sure what to make of this??
      number = "";
    }
  }

  return number;
};

// Authorization
export const prepareHeader = (state: any): Headers => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${state.id_token}`);

  // As we're not going through the Express application let's set this here
  // that would decrypt the headers -- allows us to capture the e-mail and groups
  myHeaders.append("useremail", state.email);
  myHeaders.append("groups", state.groups);

  return myHeaders;
};

export const renderStringWithHTML = (rawHTML: string) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });

export enum FormatDayType {
  FULL,
  SHORT,
  ABBREVIATION,
}

// Programs
export const formatDayList = (
  dayList: number[],
  formatDayType: FormatDayType = FormatDayType.ABBREVIATION,
): string => {
  if (!dayList || !dayList.length) return "Preferred days not specified";

  const available = dayList.sort((a, b) => a - b);

  // Group these into consecutive blocks of days (if more than 2, e.g. M-W)
  // Each block will then be named, and these names joined
  const blocks = [];
  let block = [];
  available.forEach(d => {
    if (!block.length || (d === (block[block.length - 1] + 1))) {
      block.push(d);
    }
    else {
      blocks.push(block);
      block = [d];
    }
  });
  blocks.push(block);

  const dayStrings = [];
  let days: string[];
  switch (formatDayType) {
    case FormatDayType.ABBREVIATION: {
      days = strings.dayAbbreviations;
      break;
    }
    case FormatDayType.SHORT: {
      days = strings.dayShort;
      break;
    }
    default: {
      days = strings.days;
      break;
    }
  }

  blocks.forEach(b => {
    if (b.length === 1) {
      dayStrings.push(days[b[0]]);
    }
    else if (b.length === 2) {
      dayStrings.push(days[b[0]]);
      dayStrings.push(days[b[1]]);
    }
    else {
      dayStrings.push(`${days[b[0]]}${formatDayType === FormatDayType.FULL ? " - " : "-"}${days[b[b.length - 1]]}`);
    }
  });

  return dayStrings.join(formatDayType === FormatDayType.FULL ? " / " : "/");
};

export const formatProgramDays = (program: Program, type: "available" | "preferred"): string => {
  const available: number[] = (type === "available") ? program?.getAvailableDays() : program?.getPreferredDays();

  let result: string = formatDayList(available, FormatDayType.ABBREVIATION);
  if ((program && available) && program.getDays() !== available.length) {
    const numDaysString = (program.getDays() === 1)
      ? `${program.getDays()} ${strings.programList.day}`
      : `${program.getDays()} ${strings.programList.days}`;
    result = `Choose ${numDaysString} from ${result}`;
  }

  return result;
};

interface Time {
  hour: number;
  minute: number;
}

export const formatTime = (start: Time, end: Time) => {
  const startTime: string = getTime(start.hour, start.minute);
  const endTime: string = getTime(end.hour, end.minute);

  return `${startTime} - ${endTime}`;
};

export const formatProgramTime = (program: Program): string => formatTime(
  { hour: program.getStartHour(), minute: program.getStartMinute() },
  { hour: program.getEndHour(), minute: program.getEndMinute() }
);

export const distanceBetweenPoints = (lat: number, long: number, seatLat: number, seatLong: number) => {
  if ((lat === seatLat) && (long === seatLong)) {
    return 0;
  }
  const radlat1 = Math.PI * lat / 180;
  const radlat2 = Math.PI * seatLat / 180;
  const theta = long - seatLong;
  const radtheta = Math.PI * theta / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = 3963 * Math.acos(dist);

  return dist;
};

export const equalizeCost = (seat: any) => {
  if (!seat) {
    return 0;
  }

  let seatCost = seat.cost_amount;
  if (seat.cost_frequency === "annually") {
    seatCost = seat.cost_amount / 12;
  }
  else if (seat.cost_frequency === "weekly") {
    seatCost = seat.cost_amount * 5;
  }
  else if (seat.cost_frequency === "daily") {
    seatCost = seat.cost_amount * 22;
  }
  else if (seat.cost_frequency === "hourly") {
    seatCost = seat.cost_amount * 22 * 8;
  }

  return seatCost;
};

// Finding pricing from details

export const cheapestContract = (contracts: Contract[]): Contract => {
  const costPerDay = (contract: Contract): number => {
    // -- 8 hours to a day, 5 days to a week, 22 days to a month, 261 days to a year
    const amount = contract.getCostAmount();
    switch (contract.getCostFrequency()) {
      case "hourly":
        return amount * 8;
      case "daily":
        return amount;
      case "weekly":
        return amount / 5;
      case "monthly":
        return amount / 22;
      case "annually":
        return amount / 261;
      default:
        return amount;
    }
  };

  // -- Get pricing information
  let cheapest;
  if (contracts) {
    // ignore contracts not made visible to the public
    contracts.filter(c => c.is_public).forEach(contract => {
      if (cheapest === undefined) {
        cheapest = contract;
      }
      else if (contract.getCostFrequency() === cheapest.getCostFrequency()) {
        if (contract.getCostAmount() < cheapest.getCostAmount()) {
          cheapest = contract;
        }
      }
      // -- OK, normalize to days
      else if (costPerDay(contract) < costPerDay(cheapest)) {
        cheapest = contract;
      }
    });
  }

  return cheapest;
};

export const getDirectoryUrl = (center_id: string, preview: boolean = true): string => {
  const previewParam = preview ? "?preview=true" : "";

  return `${process.env.directoryUrl}profile/${center_id}${previewParam}`;
};

export const formatPhoneNumber = (to: string): string => {
  // We only support US phone numbers
  // Strip all non-numeric characters, and make sure it starts with "+1"
  let number: string = to ? to.replace(/\D/g, "") : "";
  if (number) {
    if (number.length === 10) {
      // Great - just add +1
      number = `+1${number}`;
    }
    else if (number.length === 7) {
      // No area code? Add 206
      number = `+1206${number}`;
    }
    else if (number.length > 10) {
      // Does it start with 1? If not, add +1
      if (number.charAt(0) !== "1") {
        number = `+1${number}`;
      }
      else {
        number = `+${number}`;
      }

      // Now, assume anything after the first 12 characters is an extension
      // So we'll just cut that off
      if (number.length > 12) {
        number = number.substr(0, 12);
      }
    }
    else {
      // Not sure what to make of this??
      number = "";
    }
  }

  return number;
};

// Tour schedule information
// Note we have similar code in legup-react-components' TourRequest component

const combineStrings = (str: string[]): string => {
  if (str.length === 1) {
    return str[0];
  } if (str.length === 2) {
    return `${str[0]} and ${str[1]}`;
  }

  return `${str.slice(0, -1).join(", ")}, and ${str[str.length - 1]}`;

};

export const getTourSchedule = (tourSchedule: TourSchedule[], age_group_id: string | undefined): string => {
  const formatTime = (hour: number, minute: number): string => {
    const min: string = (`0${minute}`).slice(-2);

    return `${(hour % 12) ? (hour % 12) : 12}:${min} ${(hour < 12) ? "AM" : "PM"}`;
  };

  const formatDays = (days: Array<{day: string, idx: number}>): string => {
    // Go through, figure out consecutive blocks, and then we'll combine
    const blocks: string[] = [];
    let currentBlock: Array<{day: string, idx: number}> = [];
    days.forEach(d => {
      if (!currentBlock.length || (d.idx === currentBlock[currentBlock.length - 1].idx + 1)) {
        currentBlock.push(d);
      }
      else {
        // Close this block and start a new one
        if (currentBlock.length === 1) {
          blocks.push(currentBlock[0].day);
        }
        else {
          blocks.push(`${currentBlock[0].day} - ${currentBlock[currentBlock.length - 1].day}`);
        }
        currentBlock = [d];
      }
    });

    // And finally close the current block
    if (currentBlock.length === 1) {
      blocks.push(currentBlock[0].day);
    }
    else {
      blocks.push(`${currentBlock[0].day} - ${currentBlock[currentBlock.length - 1].day}`);
    }

    return combineStrings(blocks);
  };

  const scheduleString = (schedules: TourSchedule[]): string => {
    const tourTimes: Array<{day: string, idx: number, times: string}> = [];
    strings.days.forEach((d: string, idx: number) => {
      const dayTours: TourSchedule[] = schedules.filter(t => t.getDayOfWeek() === (idx + 1) % 7);
      if (dayTours.length) {
        const times: string[] = [];
        dayTours.forEach(t => {
          times.push(`${formatTime(t.getStartHour(), t.getStartMinute())} - ${formatTime(t.getEndHour(), t.getEndMinute())}`);
        });

        tourTimes.push({ day: d, idx, times: times.join(" and ") });
      }
    });

    // Now, let's filter down the array combining days with the same time strings
    const tours: Array<{days: Array<{day: string, idx: number}>, times: string}> = [];
    tourTimes.forEach(t => {
      const t2 = tours.find(tour => tour.times === t.times);
      if (t2) {
        t2.days.push({ day: t.day, idx: t.idx });
      }
      else {
        tours.push({ days: [{ day: t.day, idx: t.idx }], times: t.times });
      }
    });

    const days: string[] = tours.map(t => `${formatDays(t.days)} from ${t.times}`);

    return combineStrings(days);
  };

  let schedule: string = "";
  if (tourSchedule && tourSchedule.length) {
    // First, let's see if we have any age group specific times
    // If an age group ID was passed in, we will only consider that age group
    let ageGroups: string[] = [];
    tourSchedule.forEach(t => {
      if (t.getAgeGroupId() && ageGroups.indexOf(t.getAgeGroupId()) === -1) {
        ageGroups.push(t.getAgeGroupId());
      }
    });
    if (age_group_id) {
      ageGroups = ageGroups.filter(a => a === age_group_id);
    }

    // Build up the schedule. First we'll do it for all age groups
    let allAgesSchedule: string;
    const allAges: TourSchedule[] = tourSchedule.filter(t => !t.getAgeGroupId());
    if (allAges.length) {
      allAgesSchedule = scheduleString(allAges);
    }

    const ageGroupStrings: string[] = [];
    ageGroups.forEach(a => {
      const ageGroupTours: TourSchedule[] = tourSchedule.filter(t => (t.getAgeGroupId() === a));
      ageGroupStrings.push(strings.tourSchedule.scheduleAgeGroup
        .replace("{AgeGroup}", ageGroupTours[0].getAgeGroupName())
        .replace("{Schedule}", scheduleString(ageGroupTours)));
    });

    // OK, build up the approriate string to return
    if (allAgesSchedule) {
      if (ageGroups.length) {
        schedule = strings.tourSchedule.scheduleWithAgeGroups
          .replace("{Schedule}", allAgesSchedule)
          .replace("{AgeGroups}", ageGroupStrings.join(" "));
      }
      else {
        schedule = strings.tourSchedule.scheduleNoAgeGroups
          .replace("{Schedule}", allAgesSchedule);
      }
    }
    else {
      schedule = ageGroupStrings.join(" ");
    }
  }

  return schedule;
};

export const readSavedConciergeForms = async (id_token: string, email: string, groups: string, parent_id: string): Promise<{ forms: ConciergeForm[] | undefined, preferences: OpenSeatPreferences[] | undefined, retrievedData: boolean | undefined }> => {
  let forms: ConciergeForm[] | undefined;
  let preferences: OpenSeatPreferences[] | undefined;
  let retrievedData: boolean = false;

  try {
    const headers: Headers = prepareHeader({ id_token, email, groups });
    const req = await fetch(`/api/parent/${parent_id}/concierge`, {
      headers,
      method: "GET",
    });
    if (req.status === 200) {
      const data = await req.json();

      if (data.surveys?.length) {
        forms = [];
        preferences = [];
        retrievedData = !!data.retrievedData;

        data.surveys.forEach((prefs: OpenSeatPreferences) => {
          let addr: Address;
          const kid: Child = new Child();

          if (prefs.home) {
            addr = new Address();
            addr.buildFromJSON(prefs.home);
            prefs.home = addr;
          }
          if (prefs.alternate) {
            addr = new Address();
            addr.buildFromJSON(prefs.alternate);
            prefs.alternate = addr;
          }

          // That's good enough for the prefernce (for API calls)
          preferences.push(prefs);

          // Now convert to a ConciergeForm (for UI treatment)
          kid.setId(prefs.child_id);
          kid.setBirthDate(prefs.birth_date);

          // Subsidy eligible is a new question ... if this was a previously saved form,
          // then we should set it to Yes or No based on the presence or absence of subsidies
          const subsidy_eligible: "yes" | "no" | "dontknow" = prefs.subsidy_eligible || (prefs.subsidies?.length ? "yes" : "no");

          const form: ConciergeForm = {
            preference_id: prefs.preference_id,
            child: kid,
            firstTimeCare: prefs.first_time_care ? "firsttime"
              : (prefs.first_time_care === false ? "previous" : undefined),
            careTypes: prefs.care_types,
            preferred_date: prefs.preferred_date ? new Date(prefs.preferred_date) : undefined,
            mostImportant: prefs.most_important_factors,
            preferred_address: prefs.use_home_address ? (prefs.use_alternate_address ? "both" : "home")
              : (prefs.use_alternate_address ? "work" : undefined),
            milesFromAddress: prefs.miles_from_address,
            homeAddress: prefs.home,
            workAddress: prefs.alternate,
            routeInBetween: prefs.route_in_between,
            maxPrice: prefs.max_cost_amount ? { amount: prefs.max_cost_amount, frequency: prefs.max_cost_frequency } : undefined,
            subsidy_eligible,
            subsidies: prefs.subsidies,
            careSetting: prefs.preferred_center_types,
            scheduleDayCount: prefs.schedule_days,
            hearAboutUs: prefs.hear_about_us,
          };

          // OK, now let's set schedule days and hours
          if (prefs.schedule_available_days) {
            let i = prefs.schedule_available_days;
            let j: number = 0;
            const scheduleDaysOfWeek: number[] = [];

            while (i > 0) {
              if (i % 2) {
                scheduleDaysOfWeek.push(j);
              }
              j++;
              i >>= 1;
            }

            form.scheduleDaysOfWeek = scheduleDaysOfWeek;
          }

          // Probably a better way to do this...
          if (prefs.schedule_start_time && prefs.schedule_end_time) {
            form.scheduleHours = {
              start: (prefs.schedule_start_time < 420) ? "before.7" : ((prefs.schedule_start_time >= 720) ? "after.12" : "after.7"),
              end: (prefs.schedule_end_time < 780) ? "before.13" : ((prefs.schedule_end_time > 1080) ? "after.17" : "before.17"),
            };
          }

          (data.basics || []).forEach(b => {
            const values: string[] = prefs.basics?.find(b2 => b2.profile_basic_id === b.profile_basic_id)?.values;

            switch (b.code) {
              case "Curriculum":
                form.educationalPhilosophy = values;
                break;
              case "Languages Spoken":
                form.primaryLanguage = values;
                break;
              case "Languages Taught":
                form.taughtLanguages = values;
                break;
              case "Abilities":
                form.specialAbilities = values?.length ? values[0] : undefined;
                break;
              case "Wheelchair":
                form.wheelchairAccessible = values?.length ? values[0] : undefined;
                break;
            }
          });

          forms.push(form);
        });
      }
    }
  }
  catch (e) {
    // Oh, there was a problem - OK, we'll just start with a fresh set of forms
    forms = undefined;
  }

  return { forms, preferences, retrievedData };
};

export const sendDebugInfo = async (message: string, tag: string, data: any) => {
  // On local host, we should pass the full tag list in the query
  // since we won't end up going through the Express application
  const url: string = (process.env.DB_HOST === "localhost")
    ? encodeURI(`/api/tools/debug?debugMessageTags=${process.env.debugTags}`)
    : "/api/tools/debug";

  try {
    const req = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        message,
        data,
        tag,
      }),
    });
  }
  catch (e) {
    // This message is meant to be silent .. just go with it
  }
};

export const getWordsFromMonth = (monthCount: number, useAbbreviations = true) => {
  const getPlural = (number: number, word: any) => number === 1 && word.one || word.other;

  if (!monthCount) {
    return "0 yr, 0 mo";
  }

  const months = useAbbreviations ? { one: "mo", other: "mos" } : { one: "month", other: "months" };
  const years = useAbbreviations ? { one: "yr", other: "yrs" } : { one: "year", other: "years" };
  const m = monthCount % 12;
  const y = Math.floor(monthCount / 12);
  const result = [];

  if (y) {
    result.push(`${y} ${getPlural(y, years)}`);
  }
  if (m) {
    result.push(`${m} ${getPlural(m, months)}`);
  }

  return result.join(", ");
};

// Removes any HTML tags from a string that probably shouldn't have them
export const secureDisplayString = (s: string | undefined): string | undefined => s?.replace(/\s*\<.*?\>\s*/g, "");

// Strips all html tags,
// replaces tags that produce line breaks with \n,
// with p, and h tags getting 2 line breaks,
// and replaces &nbsp; with a space.
export const stripHtmlPreserveLineBreaks = (html: string): string => {
  const lineBreakElements = ["li", "div", "address", "article", "aside", "blockquote", "canvas", "dd", "dl", "dt", "fieldset", "figcaption", "figure", "footer", "form", "header", "hr", "main", "nav", "noscript", "ol", "pre", "section", "table", "tfoot", "ul", "video"];
  const doubleLineBreakElements = ["p", "h1", "h2", "h3", "h4", "h5", "h6"];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  function traverse(node: Node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      if (lineBreakElements.includes(node.nodeName.toLowerCase())) {
        node.appendChild(doc.createTextNode("\n")); // add a line break at the end
      }
      if (doubleLineBreakElements.includes(node.nodeName.toLowerCase())) {
        node.appendChild(doc.createTextNode("\n\n")); // add two line breaks at the end
      }
      // Recursively traverse child nodes
      node.childNodes.forEach(child => traverse(child));
    }
  }

  // strip all html tags and preserve line breaks
  traverse(doc.body);

  // regex to replace &nbsp; with a space
  return doc.body.textContent?.replace(/&nbsp;/g, " ");
};

export const programDaysAndHours = (schedule: any) => {
  if (!schedule?.days) return;

  const openDays = [];
  const openSchedule = [];
  schedule.days.forEach((day: {open: boolean, start: number, end: number}, index: number) => {
    if (schedule.getOpen(index)) {
      openDays.push(index);
      openSchedule.push(day);
    }
  });

  // get earliest and latest time
  let openTime : number | string = Math.min(...openSchedule.map(({ start }) => start));
  let closeTime : number | string = Math.max(...openSchedule.map(({ end }) => end));

  // format times
  if (openTime) openTime = moment.utc(moment.duration(openTime, "minutes").asMilliseconds()).format("h:mma");
  if (closeTime) closeTime = moment.utc(moment.duration(closeTime, "minutes").asMilliseconds()).format("h:mma");

  return `${formatDayList(openDays, FormatDayType.ABBREVIATION)}, ${openTime} - ${closeTime} `;
};

// center basics
export const getCenterBasicByName = (centerBasics: CenterBasics, name: string): CenterBasic | undefined => centerBasics?.find((basic : any) => basic.title === name);

export const centerBasicsNames = {
  languagesSpoken: "Languages Spoken",
  languagesTaught: "Languages Taught in Program",
  educationalPhilosophies: "Education Philosophy",
  abilities: "Different Abilities Supported",
};

export const isSectionComplete = (data: {[s: string]: any}, requiredFields: string[]) => {
  let isComplete = true;
  requiredFields.every(field => {
    if (!data?.[field]) {
      isComplete = false;

      return false;
    }

    return true;
  });

  return isComplete;
};

export type Fee = {
  amount: number,
  legup: number,
  stripe: number,
};

export const calculateFee = (fee: Fee, isProviderMRR: boolean): { ccFee: number, amount: number } => {
  const legupFee = isProviderMRR ? 0 : fee.legup;
  const ccFee = fee.stripe;
  const amount = fee.amount + legupFee;

  return { ccFee, amount };
};

// Days and hours - for hours, we calculate based on earliest open and latest close
export const daysAndHoursString = (schedule: Schedule) => {
  let idx: number;
  let minOpen: number | undefined;
  let maxClose: number | undefined;
  const dayList: number[] = [];

  for (idx = 0; idx < 7; idx++) {
    if (schedule?.getOpen(idx)) {
      dayList.push(idx);
      if (minOpen === undefined || schedule.days[idx].start < minOpen) {
        minOpen = schedule.days[idx].start;
      }
      if (maxClose === undefined || schedule.days[idx].end > maxClose) {
        maxClose = schedule.days[idx].end;
      }
    }
  }

  let time = "";
  if (minOpen !== undefined) {
    const s = new Date();
    const e = new Date();
    s.setHours(Math.min(minOpen / 60));
    s.setMinutes(minOpen % 60);
    e.setHours(Math.min(maxClose / 60));
    e.setMinutes(maxClose % 60);

    time = `${formatDayList(dayList, FormatDayType.SHORT)}, ${moment(s).format(
      "h:mma"
    )}-${moment(e).format("h:mma")}`;
  }

  return time;
};

export const getDaysIntArrayFromInteger = (days: number) => {
  let i: number = days;
  let j: number = 0;
  const scheduleDaysOfWeek: number[] = [];
  while (i > 0) {
    if (i % 2) scheduleDaysOfWeek.push(j);
    j++;
    i >>= 1;
  }

  return scheduleDaysOfWeek;
};

export const getDaysStrArrayFromInteger = (days: number) => getDaysIntArrayFromInteger(days).map(day => strings.days[day]);
