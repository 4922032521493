const Sentry = require("@sentry/nextjs");
const Transport = require("winston-transport");
const isError = require("lodash.iserror");

const SentrySeverity = {
  Debug: "debug",
  Log: "log",
  Info: "info",
  Warning: "warning",
  Error: "error",
  Fatal: "fatal",
};

const DEFAULT_LEVELS_MAP = {
  silly: SentrySeverity.Debug,
  verbose: SentrySeverity.Debug,
  info: SentrySeverity.Info,
  debug: SentrySeverity.Debug,
  warn: SentrySeverity.Warning,
  error: SentrySeverity.Error,
};

module.exports = class SentryTransport extends Transport {
  silent = false;

  constructor(opts = {}) {
    super(opts);
  }

  log(info, callback = () => {}) {
    setImmediate(() => {
      this.emit("logged", info);
    });

    const level = info.level;
    const sentryLevel = DEFAULT_LEVELS_MAP[level];
    let message = null;
    if (info.message && info.message.error && typeof info.message.error === "string") {
      message = info.message.error;
    }
    else if (typeof info.message === "string") {
      message = info.message;
    }

    if (sentryLevel === SentrySeverity.Fatal || sentryLevel === SentrySeverity.Error) {
      if (info.message && info.message.error && isError(info.message.error)) {
        Sentry.captureException(info.message.error);
      }
      else if (info.error) {
        Sentry.captureException(info.error);
      }
      else {
        Sentry.captureMessage(message, sentryLevel);
      }

      return callback();
    }

    Sentry.captureMessage(message, sentryLevel);

    return callback();
  }
};
