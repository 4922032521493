const winston = require("winston");

const SentryTransport = require("./winstonTransports/sentry");

const logger = winston.createLogger({
  defaultMeta: { service: "LegUp-admin-ui" },
  format: winston.format.combine(
    winston.format.timestamp({ format: "YYYY-MM-DD HH:mm:ss" }),
    winston.format.splat(),
    winston.format.json(),
  ),
  level: "info",
  transports: [
    new winston.transports.Console(),
    // Note: We only want to capture error logs in sentry.
    // You can change this by editing the value below.
    new SentryTransport({ level: "error" }),
  ],
});

module.exports = {
  info(message, props) {
    logger.info({ ...props, ...{ message } });
  },

  error(error, message, props = {}) {
    logger.error({ ...props, ...{ error, message } });
  },

  debug(message, props) {
    logger.debug({ ...props, ...{ message } });
  },
};
